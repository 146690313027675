(function() {
    const bodyElement = document.querySelector('body');
    
    const handleScrollingClass = function() {
        const scrollHeight = window.scrollY;
        
        const configurationContainer = document.querySelector('.configurator-container');

        if (window.innerWidth > 900 && configurationContainer) {
            const configurationOverview = document.querySelector('.advertisement-summary-content');
            const bodyPosition = bodyElement.getBoundingClientRect();
            const configurationContainerPosition = configurationContainer.getBoundingClientRect();
            const configurationContainerTop = configurationContainerPosition.top - bodyPosition.top;
            const configurationContainerBottom = configurationContainerPosition.bottom - bodyPosition.top - 100;
            const maxMargin = configurationContainerBottom - configurationContainerTop - configurationOverview.offsetHeight - 162;
            const configurationOverviewBottom = scrollHeight + configurationOverview.offsetHeight + 162;

            if (scrollHeight > configurationContainerTop && configurationOverviewBottom < configurationContainerBottom) {
                configurationOverview.style.marginTop = scrollHeight - configurationContainerTop + 'px';
            } else if (scrollHeight > configurationContainerTop) {
                configurationOverview.style.marginTop = maxMargin + 'px';
            } else {
                configurationOverview.style.marginTop = 0;
            }
        } else if (window.innerWidth <= 900 && configurationContainer) {
            const configurationOverviewFooter = document.querySelector('.advertisement-summary-sticky-footer');
            
            const bodyPosition = bodyElement.getBoundingClientRect();

            const configurationOverview = document.querySelector('.advertisement-summary-content');
            const configurationOverviewPosition = configurationOverview.getBoundingClientRect();
            const configurationOverviewTop = configurationOverviewPosition.top - bodyPosition.top;
            
            const configurationContainerPosition = configurationContainer.getBoundingClientRect();
            const configurationContainerTop = configurationContainerPosition.top - bodyPosition.top;

            if (scrollHeight > (configurationContainerTop - 450)) {
                configurationOverviewFooter.classList.add('visible');
            } else {
                configurationOverviewFooter.classList.remove('visible');
            }

            if (scrollHeight > (configurationOverviewTop - 200)) {
                configurationOverviewFooter.classList.remove('visible');
            }
        }
    };

    document.addEventListener('scroll', function () {
        handleScrollingClass();
    });

    document.querySelectorAll('.faq-header').forEach(faqHeader => {
        faqHeader.addEventListener('click', function () {
            faqHeader.closest('.faq').classList.toggle('opened');
        });
    });

    handleScrollingClass();

})();